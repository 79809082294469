import React, { Component, Fragment } from 'react';

import Globals from '../Globals';
import LibInfo from '../Lib/Info';
import LibDashboard from '../Lib/Dashboard';
import { ResponsiveBar } from '@nivo/bar';
const lodash = require('lodash');

const graphColors = {
  "default": ["#9a0059", "#AB2769", "#BA4877", "#CB6F88", "#DA9196", "#E1A19D", "#EDBDA9", "#FAD9B5"],
  "nissan": ["#c51733", "#CE3A4A", "#D7585D", "#E17B74", "#E99988", "#EEA892", "#F5C1A2", "#FCDAB3"],
};
const graphColorsStatut = ["#ec5858", "#0a85f0", "#00c366"];


class IframeStatsLeadsAndActions extends Component {

    constructor(props) {
        super(props);

        this.state = {
          nouveauxLeadsParOrigine: [],
          nouveauxLeadsParFamille: [],
          nbNouveauxLeads: null,
          maxNouveauxLeadsParFamille: 1,
          leadsEnCoursParEtat: [],
          nbLeadsEnCours: null,
          actionsParEtat: [],
          nbActions: null,
          fetchingStats: 0,
            countRDVAteliers:0
        }

        if(!localStorage.user_IDClient || !localStorage.user_login || !localStorage.user_password || localStorage.user_IDClient != this.props.location.query.idclient || localStorage.user_login != this.props.location.query.login){
            localStorage.bypass_iFrame = true;
            localStorage.user_IDClient = this.props.location.query.idclient;
            localStorage.user_login = this.props.location.query.login;
            localStorage.user_password = this.props.location.query.password;
            localStorage.token = 'iframe'
            localStorage.version = Globals.VERSION

            localStorage.ClientApiURL = Globals.API_URL;
            var parts = window.location.hostname.split('.');
            if (parts[0] === 'dev' || parts[1] === 'dev' || parts[0] === 'preprod' || parts[1] === 'preprod' || parts[0] === 'localhost') {
              localStorage.ClientApiURL = Globals.API_URL_DEV;
            }

            if (parts[0] === 'devagri' || parts[1] === 'devagri') {
              localStorage.ClientApiURL = Globals.API_URL_DEV_AGRI;
            }
        }
    }

    async componentDidMount() {
      await this.fetchUserInfo();
      await this.fetchCountRDVAtelier()
      this.fetchStats();
    }

    async fetchUserInfo() {
      this.setState({
        fetchingUserInfo: true
      })
      var userInfo = await LibInfo.get();
      userInfo = lodash.pick(userInfo, ["Utilisateur.TypeProfilLead", "Utilisateur.IDUtilisateur", "BDGp"]);
      this.setState({
        userInfo: userInfo,
        fetchingUserInfo: false
      })
    }

    async fetchStats() {
      this.setState({
        fetchingStats: 3
      })
      this.fetchCountNouveauxLeadsParOrigine();
      this.fetchActionsParEtat();
      this.fetchLeadsEnCoursParEtat();
    }

    async fetchCountRDVAtelier() {
        fetch(localStorage.ClientApiURL + '/rdvsAtelier/count', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                if(this.state.userInfo.Utilisateur.TypeProfilLead === "ChefDesVentes"){
                    this.setState({
                        countRDVAteliers:json.ToRedistribute
                    })
                }else{
                    this.setState({
                        countRDVAteliers:json.TotalToday
                    })
                }
            })
            .catch((error) => {
                console.log(error)

            });
    }

    async fetchCountNouveauxLeadsParOrigine() {
      var nouveauxLeadsParOrigine = await LibDashboard.countNouveauxLeadsParOrigine();
      var nouveauxLeadsParFamille = lodash.groupBy(nouveauxLeadsParOrigine, "Famille")
      nouveauxLeadsParFamille = lodash.map(nouveauxLeadsParFamille, (g, key) => {
        return {
          "Famille": key,
          "Count": lodash.sumBy(g, "Count")
        }
      })
      nouveauxLeadsParFamille = lodash.orderBy(nouveauxLeadsParFamille, ["Count"], ["desc"])

      var nbNouveauxLeads = lodash.sumBy(nouveauxLeadsParOrigine, "Count");
      this.setState({
        nouveauxLeadsParOrigine: nouveauxLeadsParOrigine,
        nouveauxLeadsParFamille: nouveauxLeadsParFamille,
        nbNouveauxLeads: nbNouveauxLeads,
        maxNouveauxLeadsParFamille: nouveauxLeadsParFamille.length > 0 ? lodash.max(nouveauxLeadsParFamille, "Count").Count : 0,
        fetchingStats: this.state.fetchingStats - 1
      })
    }

    async fetchActionsParEtat() {
      var resultActionsParEtat = await LibDashboard.countActionsParEtat();
      var actionsParEtat = [
        {
          "Etat": "A Venir",
          "Count": resultActionsParEtat["AVenir"],
        },
        {
          "Etat": "Aujourd'hui",
          "Count": resultActionsParEtat["Aujourdhui"],
        },
        {
          "Etat": "En Retard",
          "Count": resultActionsParEtat["EnRetard"],
        },
      ]

      var nbActions = resultActionsParEtat["EnRetard"] + resultActionsParEtat["AVenir"] + resultActionsParEtat["Aujourdhui"];
      this.setState({
        actionsParEtat: actionsParEtat,
        nbActions: nbActions,
        fetchingStats: this.state.fetchingStats - 1
      })
    }

    async fetchLeadsEnCoursParEtat() {
      var dashboardStats = await LibDashboard.getStats();

      var prefix = this.state.userInfo.Utilisateur.TypeProfilLead === "ChefDesVentes" ? "Affaire" : "";
      var leadsEnCoursParEtat = [
        {
          "Etat": "A Venir",
          "Count": dashboardStats.find(d => d.Type == prefix+"EnCoursProchain").Value,
        },
        {
          "Etat": "Aujourd'hui",
          "Count": dashboardStats.find(d => d.Type == prefix+"EnCoursAujourdhui").Value,
        },
        {
          "Etat": "En Retard",
          "Count": dashboardStats.find(d => d.Type == prefix+"EnCoursEnRetard").Value,
        },
      ]
      var nbLeadsEnCours = lodash.sumBy(leadsEnCoursParEtat, "Count");

      this.setState({
        leadsEnCoursParEtat: leadsEnCoursParEtat,
        nbLeadsEnCours: nbLeadsEnCours,
        fetchingStats: this.state.fetchingStats - 1
      })
    }

    isLoading() {
      return (this.state.fetchingUserInfo || this.state.fetchingStats);
    }

    isNissan() {
      return this.state.userInfo && this.state.userInfo.BDGp.indexOf("BDGpNi") !== -1;
    }

    getNouveauxLeadsLabel() {
      if(this.state.userInfo && this.state.userInfo.Utilisateur.TypeProfilLead === "ChefDesVentes") {
        return "Leads à redistribuer"
      }
      return "Leads à qualifier";
    }

    onClickLeadsARedistribuer(origines = []) {
      window.parent.postMessage({
        "action": "goToActionsAFaire",
        "query": "tab=leads&origines="+origines.join(",")
      }, "*")
    }

    onClickLeadsEnCours() {
      window.parent.postMessage({
        "action": "goToActionsAFaire",
        "query": "tab=leads&resetFilters=1"
      }, "*")
    }

    onClickActions() {
      window.parent.postMessage({
        "action": "goToActionsAFaire",
        "query": "tab=actions&resetFilters=1"
      }, "*")
    }
    onClickRDVAteliers() {
        window.parent.postMessage({
            "action": "goToActionsAFaire",
            "query": "tab=actions&resetFilters=1&rdvAteliers=1"
        }, "*")
    }

    /**
     * Retourne un tableau de libellés d'origines correspondantes aux paramètres famille sousFamille et origine
     * et formattées sous la forme famille-sousFamille-origine
     */
    getOriginesFullLabel(famille, sousFamille = null, origine = null) {
      if(origine) {
        return [famille+"-"+sousFamille+"-"+origine]
      }
      else {
        if(sousFamille) {
          var origines = lodash.filter(this.state.nouveauxLeadsParOrigine, (l) => l.Famille == famille && l.SousFamille == sousFamille)
        }
        else {
          var origines = lodash.filter(this.state.nouveauxLeadsParOrigine, (l) => l.Famille == famille)
        }
        return origines.map(o => {
          return o.Famille+"-"+o.SousFamille+"-"+o.Origine
        })
      }
    }

    renderGraphLeadsARedistribuer() {
      const colors = this.isNissan() ? graphColors["nissan"] : graphColors["default"];

      return (
        <div style={{display: "flex", flexDirection: "column", fontSize:'0.9em', gap: 3, padding: 5}}>
          {this.state.nouveauxLeadsParFamille.map((famille, k) => {
            var width = (famille.Count / this.state.maxNouveauxLeadsParFamille) * 100;
            var leadsCurrentFamille = lodash.filter(this.state.nouveauxLeadsParOrigine, (l) => l.Famille == famille.Famille)
            var nbLeadsParSousFamille = lodash.groupBy(leadsCurrentFamille, "SousFamille")
            nbLeadsParSousFamille = lodash.map(nbLeadsParSousFamille, (g, key) => {
              return {
                "SousFamille": key,
                "Count": lodash.sumBy(g, "Count")
              }
            })
            nbLeadsParSousFamille = lodash.orderBy(nbLeadsParSousFamille, ["Count"], ["desc"])

            return (
              <Fragment key={famille.Famille}>
                <div style={{display: "flex", flexDirection: "row", gap: 6, cursor: "pointer"}} onClick={this.onClickLeadsARedistribuer.bind(this, this.getOriginesFullLabel(famille.Famille))}>
                  <div style={{width:'35%', textAlign: "right", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} title={famille.Famille}>
                    {famille.Famille}
                  </div>
                  <div style={{width: '65%'}}>
                    <div style={{backgroundColor: colors[k], color: width > 10 ? "white" : "black", paddingLeft: 10, borderRadius: 5, width: width + "%"}} title={famille.Famille + " : "+ famille.Count}>
                      {famille.Count}
                    </div>
                  </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", gap: 6}}>
                  <div style={{width:'35%'}}>
                  </div>
                  <div style={{width: '65%'}}>
                    {nbLeadsParSousFamille.map(sousFamille => {
                    var leadsCurrentSousFamille = lodash.filter(leadsCurrentFamille, (l) => l.SousFamille == sousFamille.SousFamille)
                    var nbLeadsParOrigine = lodash.groupBy(leadsCurrentSousFamille, "Origine")
                    nbLeadsParOrigine = lodash.map(nbLeadsParOrigine, (g, key) => {
                      return {
                        "Origine": key,
                        "Count": lodash.sumBy(g, "Count")
                      }
                    })
                    nbLeadsParOrigine = lodash.orderBy(nbLeadsParOrigine, ["Count"], ["desc"])
                    return (
                        <div key={sousFamille.SousFamille} style={{display: "flex", flexDirection: "column"}}>
                          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", cursor: "pointer"}} onClick={this.onClickLeadsARedistribuer.bind(this, this.getOriginesFullLabel(famille.Famille, sousFamille.SousFamille))}>
                            <div>{sousFamille.SousFamille}</div>
                            <div style={{textAlign: "right"}}>{sousFamille.Count}</div>
                          </div>
                          {nbLeadsParOrigine.map(origine => {
                            return (
                              <div key={origine.Origine} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", paddingLeft: 20, fontSize: '0.9em', cursor: 'pointer'}} onClick={this.onClickLeadsARedistribuer.bind(this, this.getOriginesFullLabel(famille.Famille, sousFamille.SousFamille, origine.Origine))}>
                                <div>{origine.Origine}</div>
                                <div style={{textAlign: "right"}}>{origine.Count}</div>
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Fragment>
            )
          })}
        </div>
      )
    }

    render() {
      const colors = this.isNissan() ? graphColors["nissan"] : graphColors["default"];

      return (
        <div style={{maxWidth:'305px', fontFamily:'arial', backgroundColor:'white'}} className={this.isNissan() ? "style-nissan" : ""} id="iframeStatsLeadsAndActions">
          {this.isLoading() ?
            <div className="center-div" style={{paddingTop:'100px'}}>
              <br/>
              <i className="fa fa-refresh fa-spin fa-fw"></i>
            </div>
          :
            <div>
              <div><b>Actions du jour</b></div>
              <div className="panel-group" id="accordion" style={{marginBottom: 0}} role="tablist" aria-multiselectable="true">
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a role="button" style={{padding: '0.5em', fontSize:13}} data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        &#9733; {this.getNouveauxLeadsLabel()} ({this.state.nbNouveauxLeads})
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                      {this.renderGraphLeadsARedistribuer()}
                    </div>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingTwo">
                    <h4 className="panel-title">
                      <a className="collapsed" style={{padding: '0.5em', fontSize:13}} role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        &#9733; Leads en cours ({this.state.nbLeadsEnCours})
                      </a>
                    </h4>
                  </div>
                  <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div className="panel-body">
                      <div style={{height: 75}}>
                        <ResponsiveBar
                          data={this.state.leadsEnCoursParEtat}
                          indexBy="Etat"
                          keys={["Count"]}
                          colors={(d) => graphColorsStatut[this.state.leadsEnCoursParEtat.length - d.index - 1]}
                          borderRadius={5}
                          padding={0.3}
                          layout="horizontal"
                          colorBy="value"
                          axisTop={null}
                          axisRight={null}
                          axisBottom={null}
                          axisLeft={{
                              tickSize: 0,
                              tickPadding: 5,
                              format: (v) => {
                                return (
                                  <tspan onClick={this.onClickLeadsEnCours.bind(this)} style={{cursor: "pointer"}}>
                                    {v}
                                  </tspan>
                                );
                              },
                          }}
                          margin={{ top: 0, right: 5, left: 100 }}
                          tooltip={({ id, value, index, indexValue, color, data }) => {
                            return (
                              <span>
                                <svg width={10} height={10}>
                                  <rect width={10} height={10} style={{fill:color}}/>
                                </svg>
                                &nbsp;&nbsp;{indexValue} : {value}
                              </span>
                            )
                          }}
                          onClick={() => {
                            this.onClickLeadsEnCours()
                          }}
                          enableGridY={false}
                          labelFormat={(d) => {
                            return <tspan fontSize={13} fontWeight={"500"} textAnchor={"start"} x={"10"} fontFamily={"arial"} fill="black">{ d }</tspan>
                          }}
                          legends={[]}
                          animate={true}
                          motionStiffness={1000}
                          motionDamping={50}
                          theme={{
                            axis: {
                              ticks: {
                                text: {
                                  fontSize: 13,
                                  textAlign:'left',
                                }
                              }
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                      <a className="collapsed" style={{padding: '0.5em', fontSize:13}} role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        &#9734; Actions ({this.state.nbActions})
                      </a>
                    </h4>
                  </div>
                  <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body">
                      <div style={{height: 75}}>
                        <ResponsiveBar
                          data={this.state.actionsParEtat}
                          indexBy="Etat"
                          keys={["Count"]}
                          colors={(d) => graphColorsStatut[this.state.actionsParEtat.length - d.index - 1]}
                          borderRadius={5}
                          padding={0.3}
                          layout="horizontal"
                          colorBy="value"
                          axisTop={null}
                          axisRight={null}
                          axisBottom={null}
                          axisLeft={{
                              tickSize: 0,
                              tickPadding: 5,
                              format: (v) => {
                                return (
                                  <tspan onClick={this.onClickActions.bind(this)} style={{cursor: "pointer"}}>
                                    {v}
                                  </tspan>
                                );
                              },
                          }}
                          margin={{ top: 0, right: 5, left: 100 }}
                          tooltip={({ id, value, index, indexValue, color, data }) => {
                            return (
                              <span>
                                <svg width={10} height={10}>
                                  <rect width={10} height={10} style={{fill:color}}/>
                                </svg>
                                &nbsp;&nbsp;{indexValue} : {value}
                              </span>
                            )
                          }}
                          onClick={() => {
                            this.onClickActions()
                          }}
                          enableGridY={false}
                          labelFormat={(d) => {
                            return <tspan fontSize={13} fontWeight={"500"} textAnchor={"start"} x={"10"} fontFamily={"arial"} fill="black">{ d }</tspan>
                          }}
                          legends={[]}
                          animate={true}
                          motionStiffness={1000}
                          motionDamping={50}
                          theme={{
                            axis: {
                              ticks: {
                                text: {
                                  fontSize: 13,
                                  textAlign:'left',
                                }
                              }
                            },
                          }}
                        />
                      </div>
                    </div>
                      {this.state.countRDVAteliers > 0 &&
                          (this.state.userInfo.Utilisateur.TypeProfilLead === "ChefDesVentes" ?
                                  <span style={{textDecoration:"underline",cursor:"pointer"}}  onClick={this.onClickRDVAteliers.bind(this)}> Contacts RDV Atelier à affecter({this.state.countRDVAteliers})</span>
                                  :
                                  <span style={{textDecoration:"underline",cursor:"pointer"}}   onClick={this.onClickRDVAteliers.bind(this)}> Contacts RDV Atelier aujourd'hui ({this.state.countRDVAteliers})</span>)
                      }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      );
    }
}

export default IframeStatsLeadsAndActions;
